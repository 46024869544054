@import '@/styles/breakpoints.module';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 330px;
  border-radius: var(--palette-border-radius-m);

  @include tablet {
    height: 436px;
  }

  &.bgPrimary {
    background-color: var(--palette-primary-700);
  }

  &.bgSecondary {
    background-color: var(--palette-gray-175);
  }
}

.content {
  position: relative;
  padding-top: 16px;
  padding-inline: 16px;

  @include tablet {
    padding-top: 40px;
    padding-inline: 40px;
  }

  @include desktop {
    padding-inline: 12px;
    max-width: 475px;
    margin-inline: auto;
  }

  .subhead {
    min-height: 2lh;
  }

  .link {
    margin-top: 16px;
    @include tablet {
      margin-top: 24px;
    }
  }

  &.primary {
    .subhead {
      color: var(--color-text-primary);
    }

    .link {
      --color-btn-link-hover-text: var(--palette-white);
    }
  }
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  @include tablet {
    margin-top: 24px;
  }
}

.bodyImage {
  box-shadow: var(--shadow-cards-light);
  background-color: var(--palette-white);
  border-radius: 8px;
  overflow: hidden;
  width: 242px;
  height: 203px;

  @include tablet {
    border-radius: 12px;
    width: 370px;
    height: 311px;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.bodyBottomSlot {
  text-align: center;
  margin-top: 12px;
  @include tablet {
    margin-top: 16px;
  }
}

.voucherCardCustomBody {
  padding: min(4.32%, 16px);
  & > *:not(:first-child) {
    margin-top: min(2.35%, 8px);
  }
}
