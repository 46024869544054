@import '@/styles/breakpoints.module';

.wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.wrapperReviewItem{
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--palette-gray-500);

  .wrapperInfo{
    display: flex;
    align-items: center;
    gap: 12px;

    .wrapperImg{
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .wrapperTrustpilot{
      height: 20px;
      img, svg{
        width: 100%;
        height: 100%;
      }
    }
  }

  .review{
    color: var(--palette-gray-150);
  }

  .author{
    color: var(--palette-white);
    font-weight: var(--palette-font-weight-semibold);
    font-size: 16px;

    @include tablet{
      font-size: 18px;
    }
  }

  &:last-child{
    border-bottom: 1px solid transparent;
    padding-bottom: 0;
  }
}
