@import '/src/styles/breakpoints.module';

.sectionWrapper {
  @include desktop {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    & > * {
      flex: 1 1 100%;
    }

    & > *:nth-child(2) {
      max-width: 525px;
    }
  }
}

.sideWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  margin-top: var(--vstack-spacing-sm);

  .imgWrapper {
    border-radius: 16px;
    background: var(--palette-white);
    box-shadow: 2px 4px 30px 0 var(--color-shadow-base);
    padding: 8px;
    width: 101px;
    height: 101px;
    display: flex;
    justify-content: center;
    align-items: center;

    .imgItem {
      max-width: 85px;
      width: 100%;

      img,
      svg {
        width: 100%;
        height: 100%;
      }

      @include tablet {
        max-width: 132px;
      }
    }

    @include tablet {
      width: 164px;
      height: 164px;
      border-radius: var(--palette-border-radius-xxl);
    }
  }

  @include tablet {
    margin-top: var(--vstack-spacing-md);
  }
  @include desktop {
    margin-top: 0;
    justify-content: center;
    gap: 8px;
  }
}

.content {
  width: 100%;
  @include desktop {
    max-width: 600px;
  }
}

.actionButtons {
  margin-top: 24px;
}
