@import '/src/styles/breakpoints.module';

.sectionWrapper {
  @include desktop {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    & > * {
      flex: 1 1 100%;
    }

    & > *:nth-child(2) {
      max-width: 459px;
    }
  }
}

.sideWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 32px;

  @include tablet {
    margin-top: 48px;
  }
  @include desktop {
    display: block;
    margin-top: 0;
  }
}

.imageWrapper {
  position: relative;
  width: 100%;
  max-width: 459px;

  img {
    width: 100%;
    height: auto;
  }
}

.content {
  width: 100%;
  @include desktop {
    max-width: 600px;
  }
}

.wrapperCtaInfo {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
  @include tablet {
    margin-top: 32px;
  }

  a {
    flex-shrink: 0;
  }

  p {
    color: var(--palette-gray-150);
    margin-bottom: 0;
    font-size: 16px;

    span {
      font-weight: var(--palette-font-weight-semibold);
      color: var(--palette-white);
    }

    @include desktop {
      font-size: 18px;
    }
  }
}
