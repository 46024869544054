@import '@/styles/breakpoints.module';

.slide:not(:global(.is-active)) > * {
  @include tablet {
    transform: scale(0.9116);
  }
}

.sliderArrows {
  display: none;
  @include tablet {
    display: flex;
  }
}

.sliderTrackContainer {
  max-width: 100% !important;
  padding: 0 !important;
}
