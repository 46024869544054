.link {
  display: block !important;
  width: fit-content !important;
}

.disclaimer {
  color: var(--palette-white) !important;
}

.tcs {
  display: block;
  width: fit-content !important;
  margin-top: 16px !important;
  margin-bottom: 0px !important;
}
