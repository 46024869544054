@import '@/styles/breakpoints.module';

.tickersTape {
  display: flex;
  overflow: hidden;
  max-width: 1440px;
  margin: 0 auto;
  mask-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 13%,
    rgba(0, 0, 0, 1) 87%,
    rgba(0, 0, 0, 0) 100%
  );
  position: relative;

  &:hover {
    .row {
      animation-play-state: paused;
    }
  }
}

.tickersTapeCard {
  border-radius: var(--palette-border-radius-m);
  background: var(--palette-gray-700);
  padding: 16px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  width: 193px;

  .wrapperIcon {
    img,
    svg {
      width: 100%;
      height: 32px;

      @include tablet {
        height: 40px;
      }
    }
  }

  .wrapperRating {
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: var(--palette-border-radius-xxxl);
    background: var(--palette-gray-600);
    padding: 4px 12px;

    span {
      font-weight: var(--palette-font-weight-semibold);
      color: var(--palette-white);
    }

    .wrapperStar {
      width: 16px;
      height: 16px;

      img,
      svg {
        width: 100%;
        height: 100%;
      }

      @include tablet {
        width: 24px;
        height: 24px;
      }
    }

    @include tablet {
      padding: 8px 16px;
      gap: 8px;
    }
  }

  @include tablet {
    padding: 24px;
    width: 260px;
    gap: 24px;
  }

  &:hover {
    background-color: var(--palette-gray-600);
  }
}

.row {
  display: flex;
  //border: 1px solid red;
  --gap: 12px;
  margin-inline-end: var(--gap);
  @include tablet {
    --gap: 16px;
  }

  gap: var(--gap);
  animation: slide var(--speed) linear infinite;
}

[dir='rtl'] {
  .tickersTape {
    justify-content: flex-end;
  }

  .row {
    animation: slideRtl var(--speed) linear infinite;
  }
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes slideRtl {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}
